import { useFormik } from "formik";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Container, Form, Modal, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import ReactStars from "react-stars";
import * as Yup from "yup";
import Footer from "../../Commons/Footer";
import Header from "../../Commons/Headers/Header";
import LenderHeader from "../../Commons/Headers/LenderHeader";
import RenterHeader from "../../Commons/Headers/RenterHeader";
import Loader from "../../Commons/Loader/Loader";
import { chatUser } from "../../Redux/ChatUserNameSlice";
import {
  MessageWindowApi,
  RenterSummaryApi,
  chatListApi,
  loadNewMessage,
  reportUserApi,
  sendMessage,
} from "../../Services/APIServices";
import showNotification from "../../Services/NotificationService";
import { convertUTCToLocal } from "../../Services/helper";
import {
  BtnRightIcon,
  ChatBackIcon,
  ChatDetailsHideIcon,
  ChatDetailsShowIcon,
  CrossIcon,
  ImageIcon,
  MsgIcon,
  SendMsgIcon,
  ShieldIcon,
} from "../../SvgIcons/allIcons";
import { truncate } from "../../globals/HelperCommon";
import {
  BIWEEKLY,
  DEFAULT_CURRENCY,
  LENDER,
  MONTHLY,
  OFFENSIVE,
  OTHER,
  PERPAGE_TEN,
  RENTER,
  SCAMMING,
  THIRTY,
  WEEKLY,
} from "../../globals/constants";
import useRole from "../../hooks/useRole";
import { imageValidator } from "../../utils/CommonFunction";
import "./_chat.scss";
import { imageBase } from "../../globals/Config";
const NewChat = () => {
  const mobileMessagesRef = useRef(null);
  const IMAGE = 2;
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const [message, setMessage] = useState([]);
  const [totalPage, setTotalPage] = useState(1);
  const [page, setPage] = useState(1);
  const location = useLocation();
  const role = useRole();
  const [orderId, setOrderId] = useState(location?.state?.orderId);
  const [productId, setProductId] = useState(location?.state?.productId);
  const [receiverId, setReceiverId] = useState(location?.state?.recevierId);
  const currentId = JSON.parse(localStorage.getItem("userDetails"))?.id;
  const chatUserName = useSelector((state) => state?.chatName?.name);
  const [toggelScreen, setToggelScreen] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [showMedia, setShowMedia] = useState(false);
  const handleShowMedia = () => setShowMedia(true);

  const handleCloseMedia = () => {
    setShowMedia(false);
  };

  var intervalId;

  useEffect(() => {
    if (orderId && receiverId) {
      getMessageWindow();
    }
    intervalId = setInterval(() => {
      if (receiverId && orderId) {
        loadNewChatMessage();
      }
    }, 3000);
    return () => {
      clearTimeout(intervalId);
    };
  }, [orderId, receiverId]);

  useEffect(() => {
    if (orderId && receiverId && page > 1) {
      getMessageWindow(page);
    }
  }, [page]);

  const loadNewChatMessage = () => {
    let formData = new FormData();
    formData.append("receiver_id", receiverId);
    formData.append("order_id", orderId);
    loadNewMessage(formData).then((resp) => {
      if (resp?.data?.status === 200) {
        if (
          resp?.data?.data?.length &&
          currentId == resp?.data?.data[0]?.receiver
        ) {
          setMessage((prev) => [...prev, ...resp?.data?.data]);
        }
      }
    });
  };

  const getMessageWindow = (page) => {
    const body = {
      receiver_id: receiverId,
      order_id: orderId,
    };
    let formData = new FormData();
    Object.keys(body).forEach((i) => {
      formData.append(i, body[i]);
    });
    MessageWindowApi(formData, page).then((resp) => {
      if (resp?.data?.status === 200) {
        if (page) {
          setMessage([...resp?.data?.data?.reverse(), ...message]);
        } else {
          setMessage(resp?.data?.data?.reverse());
        }
        setTotalPage(resp?.data?.meta?.page_count);
      }
    });
  };

  const {
    handleSubmit,
    handleChange,
    values,
    isValid,
    setFieldValue,
    isSubmitting,
  } = useFormik({
    initialValues: {
      message_type: "",
      message: "",
      message_file: "",
      receiver_id: "",
      caption: "",
    },

    onSubmit(values, { setSubmitting, resetForm }) {
      setSubmitting(true);
      setLoading(true);

      let body = {
        message_type: values.message_file ? 2 : 1,
        message: values.message_file ? values?.caption : values?.message,
        message_file: values.message_file,
        receiver_id: receiverId,
        order_id: orderId,
      };

      let formData = new FormData();
      Object.keys(body).forEach((data) => {
        formData.append(data, body[data]);
      });
      handleCloseMedia();
      sendMessage(formData).then((resp) => {
        setLoading(false);
        setSubmitting(false);
        if (resp?.status === 200) {
          getMessageWindow();
          resetForm();
        } else {
          showNotification("danger", resp?.data?.message);
        }
      });
    },
  });

  const handlePageChange = () => {
    setPage((prev) => prev + 1);
  };

  const handleFile = (e) => {
    if (!imageValidator(e.target.files[0])) {
      return false;
    }
    if (orderId && receiverId) {
      setFieldValue("message_file", e.target.files[0]);
      handleShowMedia();
    } else {
      showNotification("danger", "Please select user.");
    }
  };

  /******************************Chat List functionality*******************/
  const [chatList, setChatList] = useState([]);

  useEffect(() => {
    getChatSaved();
  }, []);

  const getChatSaved = (search = "", page = 1, perPage = PERPAGE_TEN) => {
    chatListApi(search, page, perPage).then((resp) => {
      setLoading(false);
      if (resp?.data?.status === 200) {
        setChatList(resp?.data?.data);
      }
    });
  };

  /*********************************Rental Summary*************************/
  const [summary, setSummary] = useState("");

  useEffect(() => {
    if (orderId) {
      getRentalSummary(orderId);
    }
  }, [orderId]);

  const getRentalSummary = (id) => {
    RenterSummaryApi(id).then((resp) => {
      if (resp?.data?.status === 200) {
        setSummary(resp?.data?.data);
      }
    });
  };

  const discountCalculator = (days) => {
    if (WEEKLY <= days && days < BIWEEKLY) {
      return summary?.product_detail?.weekly_discount;
    } else if (BIWEEKLY <= days && days < MONTHLY) {
      return summary?.product_detail?.biweekly_discount;
    } else if (MONTHLY <= days && days <= THIRTY) {
      return summary?.product_detail?.monthly_discount;
    } else {
      return summary?.product_detail?.discount;
    }
  };

  const numberOfDays = () => {
    if (summary?.booked_to && summary?.booked_from) {
      let diff = moment(summary?.booked_to).diff(summary?.booked_from, "days");
      return Number(diff) + 1;
    } else {
      return 0;
    }
  };

  const discountType = (days) => {
    if (WEEKLY <= days < BIWEEKLY) {
      return "Weekly discount";
    } else if (BIWEEKLY <= days < MONTHLY) {
      return "Bi-Weekly discount";
    } else if (MONTHLY <= days < THIRTY) {
      return "Monthly discount";
    } else {
      return "Discount";
    }
  };

  const TotalAmountCalculator = (type) => {
    let rent =
      (Number(summary?.sub_total ? summary?.sub_total : 0) *
        (100 - Number(discountCalculator(numberOfDays())))) /
      100;

    if (type == RENTER) {
      return (
        Number(rent) +
        Number(summary?.product_detail?.shaxify_renter_fee) +
        Number(summary?.shipping_fee?.shipping_fee)
      );
    } else {
      return Number(rent) - Number(summary?.product_detail?.shaxify_lender_fee);
    }
  };

  const handleViewProduct = (data) => {
    if (role == RENTER) {
      navigate(`/renter/renter-details?id=${data?.product_detail?.product_id}`);
    } else {
      navigate(
        `/lender/reservation-detail?id=${data?.id}&pid=${data?.product_detail?.product_id}`
      );
    }
  };

  /*******************************Report User*********************/

  const validationReport = Yup.object({
    report_type: Yup.string().required("Select reason to proceed"),
  });

  const report = useFormik({
    initialValues: {
      report_type: "",
    },
    validationSchema: validationReport,

    onSubmit(values, { setSubmitting, resetForm }) {
      setSubmitting(true);

      let formData2 = new FormData();
      formData2.append("user_id", receiverId);
      formData2.append("report_type", values?.report_type);

      reportUserApi(formData2).then((resp) => {
        setSubmitting(false);
        if (resp?.data?.status === 200) {
        }
        resetForm();
        handleClose();
      });
    },
  });

  useEffect(() => {
    mobileMessagesRef.current?.scrollIntoView({
      block: "end",
      inline: "center",
      behavior: "smooth",
      alignToTop: false,
    });
  }, [message]);

  const discountPercentage = discountCalculator(numberOfDays()) || 0;
  const subTotal = summary?.sub_total || 0;
  const discountValue = (subTotal * discountPercentage) / 100;
  return (
    <>
      <div className="mobile_chat_module">
        <div className="chat-header">
          {role == LENDER ? (
            <LenderHeader />
          ) : role == RENTER ? (
            <RenterHeader />
          ) : (
            <Header />
          )}

          <div className="top-head-box">
            <Container>
              <div className="row  align-items-center">
                <Col sm={12} className="px-0">
                  <ul className="d-flex flex-wrap align-items-center subtop-menu">
                    <li className="active">
                      <Link
                        to={
                          role === RENTER
                            ? "/renter/message"
                            : "/lender/message"
                        }
                      >
                        Message Center
                      </Link>
                    </li>
                  </ul>
                </Col>
              </div>
            </Container>
          </div>
        </div>
        <div
          className={toggelScreen ? "main_chatbox " : "main_chatbox list_box"}
        >
          <div className="chat_inner_box">
            <Container>
              <Row>
                {toggelScreen ? (
                  <Col sm={12} className="green-border">
                    <div className="chat_view">
                      <div className="topbar_chatmain d-flex justify-content-between">
                        <div className="leftside-chatmain d-flex">
                          <span
                            className="righttoggleicon pt-0"
                            onClick={() => {
                              setToggelScreen(false);
                              setShowDetails(false);
                            }}
                          >
                            <ChatBackIcon />
                          </span>
                          <p className="font16 font700 mb-0">{chatUserName}</p>
                        </div>
                        <span
                          className="show_detail"
                          onClick={() => setShowDetails(!showDetails)}
                        >
                          {showDetails ? (
                            <ChatDetailsHideIcon />
                          ) : (
                            <ChatDetailsShowIcon />
                          )}
                        </span>
                      </div>
                      {showDetails &&
                        (role === LENDER ? (
                          <div className="summary_box green-border-bottom">
                            <ul className="users-list">
                              <h4 className="my-3">Rental Summary</h4>
                              {productId ? (
                                <>
                                  <li>
                                    <img
                                      src={
                                        summary?.product_detail?.image
                                          ? imageBase +
                                          summary?.product_detail?.image
                                          : require("../../assets/images/img1.jpg")
                                      }
                                      alt="Img"
                                      className="chat-product-img"
                                    />
                                    <p className="msg font16 font700 colordark my-2">
                                      {summary?.product_detail?.product_title}
                                      <Link
                                        onClick={(e) => {
                                          e.preventDefault();
                                          handleViewProduct(summary);
                                        }}
                                        className="float-end"
                                      >
                                        <BtnRightIcon />
                                      </Link>
                                    </p>
                                    <p className="msg font14 font400 colordark mb-1">
                                      {`${moment(summary?.booked_from).format(
                                        "ll"
                                      )} - ${moment(summary?.booked_to).format(
                                        "ll"
                                      )}`}
                                    </p>
                                  </li>
                                  <li className="d-flex align-items-start mb-3">
                                    <img
                                      width="50"
                                      height="50"
                                      className="rounded-circle"
                                      src={
                                        summary?.booked_by_detail?.profile_pic
                                          ? imageBase +
                                          summary?.booked_by_detail
                                            ?.profile_pic
                                          : require("../../assets/images/DummyImg.png")
                                      }
                                      alt="Img"
                                    />
                                    <div className="ms-3">
                                      <h5 className="font14 font700 colorgreen mb-1">
                                        Future Renter
                                      </h5>
                                      <p className="user-name font14 font700 colordark mb-0">
                                        {summary?.booked_by_detail?.full_name}
                                      </p>
                                      <div className="starlist ">
                                        <ReactStars
                                          value={
                                            summary?.product_detail
                                              ?.average_rating
                                          }
                                          count={5}
                                          size={24}
                                          color2={"#ffd700"}
                                          edit={false}
                                        />
                                      </div>
                                    </div>
                                  </li>
                                  <li className="mb-3">
                                    <h4>Rental details</h4>
                                    <p className="font14 font700 colordark my-4">
                                      Lender send item by:{" "}
                                      {moment(
                                        summary?.rental_details
                                          ?.lender_send_item_date
                                      ).format(" YYYY-MM-DD")}
                                    </p>
                                    <p className="font14 font400 colordark mb-4">
                                      Item received by renter by:{" "}
                                      {moment(
                                        summary?.rental_details
                                          ?.renter_received_date
                                      ).format(" YYYY-MM-DD")}
                                    </p>
                                    <p className="font14 font400 colordark mb-4">
                                      Renter sends back item by:{" "}
                                      {moment(
                                        summary?.rental_details
                                          ?.renter_send_back_date
                                      ).format(" YYYY-MM-DD")}
                                    </p>
                                    <p className="font14 font700 colordark mb-4">
                                      Lender receives back item by:{" "}
                                      {moment(
                                        summary?.rental_details
                                          ?.Lender_receive_back_date
                                      ).format(" YYYY-MM-DD")}
                                    </p>
                                  </li>
                                  <li className="mb-3  border-bottom">
                                    <Link
                                      onClick={(e) => {
                                        e.preventDefault();
                                        handleShow();
                                      }}
                                      className="colordark btn btn-default d-flex align-items-center gap-3"
                                    >
                                      <ShieldIcon /> Report Renter
                                    </Link>
                                  </li>

                                  <li className="mb-3 ">
                                    <h4 className="d-flex justify-content-between align-items-center">
                                      Shipping Address
                                    </h4>
                                    <p className="msg font16 font300 ">
                                      {
                                        summary?.rental_details
                                          ?.shipping_address
                                      }
                                    </p>
                                  </li>

                                  <li className="mb-3">
                                    <h4>Renter paid</h4>
                                    <ul>
                                      <li className="d-flex align-items-center justify-content-between font14 font400">
                                        Rentals
                                        <span>
                                          {" "}
                                          {DEFAULT_CURRENCY}{" "}
                                          {summary?.sub_total}
                                        </span>
                                      </li>
                                      {discountCalculator(numberOfDays()) && (
                                        <li className="d-flex align-items-center justify-content-between font16 font400">
                                          {discountType(numberOfDays())} ({discountCalculator(numberOfDays())}%)
                                          <span>
                                            {DEFAULT_CURRENCY} {discountValue?.toLocaleString("hi")}
                                          </span>
                                        </li>
                                      )}
                                      <li className="d-flex align-items-center justify-content-between font14 font400">
                                        Shaxify fee{" "}
                                        <span>
                                          {" "}
                                          {DEFAULT_CURRENCY}
                                          {summary?.product_detail
                                            ?.shaxify_renter_fee
                                            ? Number(
                                              summary?.product_detail
                                                ?.shaxify_renter_fee
                                            )?.toLocaleString("hi")
                                            : 0}{" "}
                                        </span>
                                      </li>
                                      <li className="d-flex align-items-center justify-content-between font16 font400">
                                        Shipping fee{" "}
                                        <span>
                                          {DEFAULT_CURRENCY}
                                          {summary?.shipping_fee?.shipping_fee
                                            ? Number(
                                              summary?.shipping_fee
                                                ?.shipping_fee
                                            )?.toLocaleString("hi")
                                            : 0}{" "}
                                        </span>
                                      </li>
                                      <li className="d-flex align-items-center justify-content-between font14 font700">
                                        Total{" "}
                                        <span>
                                          {DEFAULT_CURRENCY}
                                          {Number(
                                            TotalAmountCalculator(RENTER)
                                          ).toLocaleString("hi")}
                                        </span>
                                      </li>
                                    </ul>
                                  </li>
                                  <li className="mb-3">
                                    <h4>Lender payout</h4>
                                    <ul>
                                      <li className="d-flex align-items-center justify-content-between font14 font400">
                                        Sub Total{" "}
                                        <span>
                                          {DEFAULT_CURRENCY}{" "}
                                          {summary?.sub_total}
                                        </span>
                                      </li>
                                      {discountCalculator(numberOfDays()) && (
                                        <li className="d-flex align-items-center justify-content-between font16 font400">
                                          {discountType(numberOfDays())} ({discountCalculator(numberOfDays())}%)
                                          <span>
                                            {" "}
                                            {DEFAULT_CURRENCY} {discountValue?.toLocaleString("hi")}
                                          </span>
                                        </li>
                                      )}
                                      <li className="d-flex align-items-center justify-content-between font14 font400">
                                        Shaxify fee{" "}
                                        <span>
                                          {" "}
                                          {DEFAULT_CURRENCY}
                                          {summary?.product_detail
                                            ?.shaxify_lender_fee
                                            ? Number(
                                              summary?.product_detail
                                                ?.shaxify_lender_fee
                                            )?.toLocaleString("hi")
                                            : 0}
                                        </span>
                                      </li>
                                      <li className="d-flex align-items-center justify-content-between font14 font700">
                                        Total{" "}
                                        <span>
                                          {" "}
                                          {DEFAULT_CURRENCY}
                                          {Number(
                                            TotalAmountCalculator(LENDER)
                                          ).toLocaleString("hi")}
                                        </span>
                                      </li>
                                    </ul>
                                  </li>
                                </>
                              ) : (
                                <div className="justify-content-center d-flex align-items-center h40vh">
                                  <p className="font16 mb-0 ">
                                    No associated rental
                                  </p>
                                </div>
                              )}
                            </ul>
                          </div>
                        ) : (
                          <div className="summary_box green-border-bottom">
                            <ul className="users-list">
                              <h4 className="my-3">Rental Summary</h4>
                              {productId ? (
                                <>
                                  <li>
                                    <img
                                      src={
                                        summary?.product_detail?.image
                                          ? imageBase +
                                          summary?.product_detail?.image
                                          : require("../../assets/images/img1.jpg")
                                      }
                                      alt="Img"
                                      className="chat-product-img"
                                    />
                                    <p className="msg font16 font700 colordark my-2">
                                      {summary?.product_detail?.product_title}
                                      <Link
                                        onClick={(e) => {
                                          e.preventDefault();
                                          handleViewProduct(summary);
                                        }}
                                        className="float-end"
                                      >
                                        <BtnRightIcon />
                                      </Link>
                                    </p>
                                    <p className="msg font14 font400 colordark mb-1">
                                      {`${moment(summary?.booked_from).format(
                                        "ll"
                                      )} - ${moment(summary?.booked_to).format(
                                        "ll"
                                      )}`}
                                    </p>
                                  </li>
                                  <li className="d-flex align-items-start mb-3">
                                    <img
                                      width="50"
                                      height="50"
                                      className="rounded-circle"
                                      src={
                                        summary?.product_created_by?.profile_pic
                                          ? imageBase +
                                          summary?.product_created_by
                                            ?.profile_pic
                                          : require("../../assets/images/DummyImg.png")
                                      }
                                      alt="Img"
                                    />
                                    <div className="ms-3">
                                      <h5 className="font14 font700 colorgreen mb-1">
                                        Your Lender
                                      </h5>
                                      <p className="user-name font14 font700 colordark mb-0">
                                        {summary?.product_created_by?.full_name}
                                      </p>
                                      <div className="starlist ">
                                        <ReactStars
                                          value={
                                            summary?.product_detail
                                              ?.average_rating
                                          }
                                          count={5}
                                          size={24}
                                          color2={"#ffd700"}
                                          edit={false}
                                        />
                                      </div>
                                    </div>
                                  </li>
                                  <li className="mb-3">
                                    <h4>Rental details</h4>
                                    <p className="font14 font700 colordark my-4">
                                      Lender send item by:{" "}
                                      {moment(
                                        summary?.rental_details
                                          ?.lender_send_item_date
                                      ).format(" YYYY-MM-DD")}
                                    </p>
                                    <p className="font14 font400 colordark mb-4">
                                      Item received by renter by:{" "}
                                      {moment(
                                        summary?.rental_details
                                          ?.renter_received_date
                                      ).format(" YYYY-MM-DD")}
                                    </p>
                                    <p className="font14 font400 colordark mb-4">
                                      Renter sends back item by:{" "}
                                      {moment(
                                        summary?.rental_details
                                          ?.renter_send_back_date
                                      ).format(" YYYY-MM-DD")}
                                    </p>
                                    <p className="font14 font700 colordark mb-4">
                                      Lender receives back item by:{" "}
                                      {moment(
                                        summary?.rental_details
                                          ?.Lender_receive_back_date
                                      ).format(" YYYY-MM-DD")}
                                    </p>
                                  </li>
                                  <li className="mb-3  border-bottom">
                                    <Link
                                      onClick={(e) => {
                                        e.preventDefault();
                                        handleShow();
                                      }}
                                      className="colordark btn btn-default d-flex align-items-center gap-3"
                                    >
                                      <ShieldIcon /> Report Lender
                                    </Link>
                                  </li>
                                  <li className="mb-3">
                                    <h4>Payment Summary</h4>
                                    <ul>
                                      <li className="d-flex align-items-center justify-content-between font16 font400">
                                        Rentals
                                        <span>
                                          {DEFAULT_CURRENCY}{" "}
                                          {summary?.sub_total}
                                        </span>
                                      </li>
                                      {discountCalculator(numberOfDays()) && (
                                        <li className="d-flex align-items-center justify-content-between font16 font400">
                                          {discountType(numberOfDays())} ({discountCalculator(numberOfDays())}%)
                                          <span>
                                            {" "}
                                            {DEFAULT_CURRENCY} {discountValue?.toLocaleString("hi")}
                                          </span>
                                        </li>
                                      )}

                                      <li className="d-flex align-items-center justify-content-between font16 font400">
                                        Shaxify fee
                                        <span>
                                          {DEFAULT_CURRENCY}
                                          {summary?.product_detail
                                            ?.shaxify_renter_fee
                                            ? Number(
                                              summary?.product_detail
                                                ?.shaxify_renter_fee
                                            )?.toLocaleString("hi")
                                            : 0}
                                        </span>
                                      </li>
                                      <li className="d-flex align-items-center justify-content-between font16 font400">
                                        Shipping fee
                                        <span>
                                          {DEFAULT_CURRENCY}
                                          {summary?.shipping_fee?.shipping_fee
                                            ? Number(
                                              summary?.shipping_fee
                                                ?.shipping_fee
                                            )?.toLocaleString("hi")
                                            : 0}
                                        </span>
                                      </li>
                                      <li className="d-flex align-items-center justify-content-between font16 font700">
                                        Total
                                        <span>
                                          {DEFAULT_CURRENCY}
                                          {Number(
                                            TotalAmountCalculator(RENTER)
                                          ).toLocaleString("hi")}
                                        </span>
                                      </li>
                                    </ul>
                                  </li>

                                  <li className="mb-3 ">
                                    <h4 className="d-flex justify-content-between align-items-center ">
                                      Shipping Address
                                    </h4>
                                    <p className="msg font16 font300 mt-3 ">
                                      {
                                        summary?.rental_details
                                          ?.shipping_address
                                      }
                                      <br />
                                      {summary?.rental_details?.address2}
                                      <br />
                                      {summary?.rental_details?.city}
                                      <br />
                                      {summary?.rental_details?.state}
                                      <br />
                                      {summary?.rental_details?.country}
                                      <br />
                                      {summary?.rental_details?.zipcode}
                                    </p>
                                  </li>
                                </>
                              ) : (
                                <div className="justify-content-center d-flex align-items-center h40vh">
                                  <p className="font16 mb-0 ">
                                    No associated rental
                                  </p>
                                </div>
                              )}
                            </ul>
                          </div>
                        ))}

                      {message && message.length > 0 && (
                        <>
                          <p className="text-center font16 font700 colordark mt-lg-5 mt-3">
                            {/* {moment().format("ll")} */}
                            Today
                          </p>
                          {page < totalPage && (
                            <span
                              className="load_previous"
                              onClick={handlePageChange}
                            >
                              Load Previous
                            </span>
                          )}
                        </>
                      )}
                      <ul
                        className={
                          message?.length == 0 ? "h-100" : "messages-list"
                        }
                      >
                        {message && message.length > 0 ? (
                          message?.map((data, index) => {
                            return (
                              <li className="mb-0" key={index}>
                                <h5 className="font14 font700 colordark mb-3">
                                  {data?.sender?.full_name}
                                  <span className="font14 font400 ms-2">
                                    {moment(
                                      convertUTCToLocal(data?.created_on)
                                    ).format(" h:mm a")}
                                  </span>
                                </h5>
                                {data?.message_type == IMAGE ? (
                                  <div className="chat-img">
                                    <img
                                      src={
                                        data?.message_file &&
                                        imageBase + data?.message_file
                                      }
                                      alt="img"
                                    />
                                    <p className="msg font16 font400 colordark mb-0">
                                      {data?.message}
                                    </p>
                                  </div>
                                ) : (
                                  <p className="msg font16 font400 colordark mb-0">
                                    {data?.message}
                                  </p>
                                )}
                              </li>
                            );
                          })
                        ) : (
                          <div
                            className={
                              "justify-content-center d-flex align-items-center h60vh chatbox-empty"
                            }
                          >
                            <p className="font16 mb-0 text-center">
                              No messages to display
                            </p>
                          </div>
                        )}
                        <div ref={mobileMessagesRef} />
                      </ul>
                    </div>
                  </Col>
                ) : (
                  <Col sm={12} className="green-border">
                    <ul className="users-list">
                      {chatList && chatList?.length > 0
                        ? chatList?.map((data, index) => {
                          return (
                            <li
                              className="d-flex align-items-center"
                              key={index}
                              onClick={() => {
                                setToggelScreen(true);
                                setOrderId(data?.product_detail?.order_id);
                                setReceiverId(data?.receiver?.id);
                                setProductId(
                                  data?.product_detail?.product_id
                                );
                                dispatch(chatUser(data?.receiver?.full_name));
                              }}
                            >
                              <img
                                width="80"
                                height="80"
                                className="rounded-circle"
                                src={
                                  data?.receiver?.profile_pic
                                    ? imageBase + data?.receiver?.profile_pic
                                    : require("../../assets/images/DummyImg.png")
                                }
                                alt="Img"
                              />
                              <div className="ms-3">
                                <h5 className="font14 font700 colorgreen mb-1">
                                  Future Renter
                                </h5>
                                <p className="msg font14 font400 colordark mb-2">
                                  {data?.last_message?.message_file ? (
                                    <>
                                      <img
                                        src={
                                          data?.last_message?.message_file
                                            ? imageBase +
                                            data?.last_message?.message_file
                                            : require("../../assets/images/static_image.jpg")
                                        }
                                        height={30}
                                        width={30}
                                        className="me-2"
                                      />
                                      Media file
                                    </>
                                  ) : (
                                    truncate(data?.last_message?.message, 35)
                                  )}
                                </p>
                                <p className="msg font14 font700 colordark mb-2">
                                  {truncate(
                                    `${moment(
                                      data?.product_detail?.from
                                    ).format("MMM DD")} - ${moment(
                                      data?.product_detail?.to
                                    ).format("MMM DD")} - ${data?.product_detail?.product_name
                                    }`,
                                    35
                                  )}
                                </p>
                                <p className="user-name font14 font700 colordark mb-0">
                                  {data?.receiver?.full_name}
                                </p>
                              </div>
                            </li>
                          );
                        })
                        : !loading && (
                          <div className="justify-content-center d-flex flex-column align-items-center h60vh ">
                            <MsgIcon />
                            <p className="font16 font700 mb-0 mt-2">
                              You do not have any conversations yet
                            </p>
                          </div>
                        )}
                    </ul>
                  </Col>
                )}
              </Row>
            </Container>
          </div>
        </div>

        {toggelScreen ? (
          <div className="chat-footer">
            <div className="send_msg_box">
              <label htmlFor="chooseFile">
                <ImageIcon />
              </label>

              <Form.Control
                type="text"
                name="message"
                value={values.message}
                onChange={handleChange}
                placeholder="Type a message..."
                className="send-msg"
              />
              <input
                type="file"
                accept="image/*"
                name="fileUrl"
                hidden
                id="chooseFile"
                onClick={(e) => {
                  e.target.value = null;
                }}
                onChange={handleFile}
              />
              <span
                className="greensmallbtn d-flex align-items-center justify-content-center"
                onClick={() => {
                  if (isValid && values?.message?.trim()) {
                    handleSubmit();
                  }
                }}
              >
                <SendMsgIcon />
              </span>
            </div>
            <Footer />
          </div>
        ) : (
          <Footer />
        )}
      </div>
      {/* Upload Pic Modal */}

      <Modal
        show={show}
        onHide={handleClose}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="delete-items"
      >
        <div className="middle-area text-start">
          <div className="d-flex justify-content-between align-items-center mb-2">
            <h4 className="text-start">
              {role === RENTER ? "Report Lender" : "Report Renter"}
            </h4>
            <a onClick={handleClose}>
              <CrossIcon />
            </a>
          </div>
          <Modal.Body className="px-0 pb-0 pt-0">
            <div className="single-item">
              <p className="font14 font700 colodark text-left">
                This info will only be shared with Shaxify
              </p>
            </div>
            <Row>
              <Col sm={12} className="mx-auto">
                <div className="d-flex align-items-center gap-2 my-3">
                  <div className="custom-checkbox mb-0">
                    <Form.Check
                      type="radio"
                      name="report_type"
                      value={SCAMMING}
                      onChange={report?.handleChange}
                    />
                  </div>
                  <p className="mb-0 font16 font400 colordark">
                    I think they are Scamming Me
                  </p>
                </div>
                <div className="d-flex align-items-center gap-2 my-3">
                  <div className="custom-checkbox mb-0">
                    <Form.Check
                      type="radio"
                      name="report_type"
                      value={OFFENSIVE}
                      onChange={report?.handleChange}
                    />
                  </div>
                  <p className="mb-0 font16 font400 colordark">Offensive</p>
                </div>
                <div className="d-flex align-items-center gap-2 my-3">
                  <div className="custom-checkbox mb-0">
                    <Form.Check
                      type="radio"
                      name="report_type"
                      value={OTHER}
                      onChange={report?.handleChange}
                    />
                  </div>
                  <p className="mb-0 font16 font400 colordark">
                    Something else
                  </p>
                </div>

                <span className="text-start required">
                  {report?.errors?.report_type}
                </span>

                <div className="text-center">
                  <button
                    className="btn btn-theme mt-3 mb-1 w-50 mx-auto"
                    type="button"
                    onClick={(e) => {
                      report?.handleSubmit();
                    }}
                  >
                    Send
                  </button>
                </div>
              </Col>
            </Row>
          </Modal.Body>
        </div>
      </Modal>

      {/****************************Modal to send Image********************/}

      <Modal
        show={showMedia}
        onHide={handleCloseMedia}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="delete-items"
      >
        <div className="middle-area text-start">
          <div className="d-flex justify-content-between align-items-center mb-2">
            <h4 className="text-start">Media</h4>
            <span onClick={handleCloseMedia}>
              <CrossIcon />
            </span>
          </div>
          <Modal.Body className="px-0 pb-0">
            <Row>
              <Col sm={12} className="mx-auto">
                <div className="product-img">
                  <input
                    type="file"
                    accept="image/*"
                    name="fileUrl"
                    hidden
                    id="chooseFile"
                    onClick={(e) => {
                      e.target.value = null;
                    }}
                    onChange={handleFile}
                  />

                  <label htmlFor="chooseFile">
                    <img
                      src={
                        values?.message_file &&
                        URL.createObjectURL(values?.message_file)
                      }
                      alt="img"
                      className="h-auto"
                    />
                  </label>
                </div>
                <div>
                  <p className="font12 font700 colordark mb-1">Caption</p>
                  <Form.Control
                    as="textarea"
                    rows={2}
                    className="h-auto px-2 "
                    name="caption"
                    value={values?.caption}
                    onChange={handleChange}
                  />
                </div>

                <div className="text-start">
                  <Button
                    className="btn btn-theme mt-3 mb-1"
                    type="button"
                    disabled={isSubmitting}
                    onClick={handleSubmit}
                  >
                    Send
                  </Button>
                </div>
              </Col>
            </Row>
          </Modal.Body>
        </div>
      </Modal>
      {loading && <Loader />}
    </>
  );
};

export default NewChat;
