/**
 *@copyright : ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
@author : Shiv Charan Panjeta < shiv@toxsl.com >
All Rights Reserved.
Proprietary and confidential : All information contained herein is, and remains
the property of ToXSL Technologies Pvt. Ltd. and its partners.
Unauthorized copying of this file, via any medium is strictly prohibited. */

import { useFormik } from "formik";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Container, Form, Modal, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import Footer from "../../Commons/Footer";
import Header from "../../Commons/Headers/Header";
import LenderHeader from "../../Commons/Headers/LenderHeader";
import RenterHeader from "../../Commons/Headers/RenterHeader";
import {
  MessageWindowApi,
  loadNewMessage,
  sendMessage,
} from "../../Services/APIServices";
import showNotification from "../../Services/NotificationService";
import { convertUTCToLocal } from "../../Services/helper";
import {
  ChatBackIcon,
  ChatDetailsShowIcon,
  CrossIcon,
  ImageIcon,
  SendMsgIcon,
} from "../../SvgIcons/allIcons";
import { LENDER, RENTER } from "../../globals/constants";
import useRole from "../../hooks/useRole";
import { imageValidator } from "../../utils/CommonFunction";
import MessageLeftBar from "../Message/MessageLeftBar";
import MessageRentalSummary from "../Message/MessageRentalSummary";
import MessageLenderSummary from "./MessageLenderSummary";
import NewChat from "./NewChat";
import Loader from "../../Commons/Loader/Loader";
import { imageBase } from "../../globals/Config";

export const Chat = () => {
  const messagesRef = useRef(null);
  const IMAGE = 2;
  const [message, setMessage] = useState([]);
  const [totalPage, setTotalPage] = useState(1);
  const [page, setPage] = useState(1);
  const location = useLocation();
  const role = useRole();
  const [orderId, setOrderId] = useState(location?.state?.orderId);
  const [productId, setProductId] = useState(location?.state?.productId);
  const [loading, setLoading] = useState(false);
  const [receiverId, setReceiverId] = useState(location?.state?.recevierId);
  const currentId = JSON.parse(localStorage.getItem("userDetails"))?.id;
  const userRole = useSelector((state) => state?.Login?.userDetails?.role_id);
  const chatUserName = useSelector((state) => state?.chatName?.name);
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);

  const handleClose = () => {
    setShow(false);
  };

  var intervalId;

  useEffect(() => {
    if (orderId && receiverId) {
      getMessageWindow();
    }
    intervalId = setInterval(() => {
      if (receiverId && orderId) {
        loadNewChatMessage();
      }
    }, 3000);
    return () => {
      clearTimeout(intervalId);
    };
  }, [orderId, receiverId]);

  useEffect(() => {
    if (orderId && receiverId && page > 1) {
      getMessageWindow(page);
    }
  }, [page]);

  const loadNewChatMessage = () => {
    let formData = new FormData();
    formData.append("receiver_id", receiverId);
    formData.append("order_id", orderId);
    loadNewMessage(formData).then((resp) => {
      if (resp?.data?.status === 200) {
        if (
          resp?.data?.data?.length &&
          currentId == resp?.data?.data[0]?.receiver
        ) {
          setMessage((prev) => [...prev, ...resp?.data?.data]);
        }
      }
    });
  };

  const getMessageWindow = (page) => {
    const body = {
      receiver_id: receiverId,
      order_id: orderId,
    };
    let formData = new FormData();
    Object.keys(body).forEach((i) => {
      formData.append(i, body[i]);
    });
    MessageWindowApi(formData, page).then((resp) => {
      if (resp?.data?.status === 200) {
        if (page) {
          setMessage([...resp?.data?.data?.reverse(), ...message]);
        } else {
          setMessage(resp?.data?.data?.reverse());
        }

        setTotalPage(resp?.data?.meta?.page_count);
      }
    });
  };

  const {
    handleSubmit,
    handleChange,
    values,
    isValid,
    setFieldValue,
    isSubmitting,
  } = useFormik({
    initialValues: {
      message_type: "",
      message: "",
      message_file: "",
      receiver_id: "",
      caption: "",
    },

    onSubmit(values, { setSubmitting, resetForm }) {
      setSubmitting(true);
      setLoading(true);
      let body = {
        message_type: values.message_file ? 2 : 1,
        message: values.message_file ? values?.caption : values.message,
        message_file: values.message_file,
        receiver_id: receiverId,
        order_id: orderId,
      };

      let formData = new FormData();
      Object.keys(body).forEach((data) => {
        formData.append(data, body[data]);
      });

      sendMessage(formData).then((resp) => {
        setSubmitting(false);
        setLoading(false);
        if (resp?.status === 200) {
          getMessageWindow();
          handleClose();
          scrollToBottom();
          resetForm();
        } else {
          showNotification("danger", resp?.data?.message);
        }
      });
    },
  });

  const handleKeydown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      if (values?.message?.trim().length === 0) {
        return;
      }
      if (isValid) {
        handleSubmit();
      }
    }
  };

  const handlePageChange = () => {
    setPage((prev) => prev + 1);
  };

  const handleFile = (e) => {
    if (!imageValidator(e.target.files[0])) {
      return false;
    }
    if (orderId && receiverId) {
      setFieldValue("message_file", e.target.files[0]);
      handleShow();
    } else {
      showNotification("danger", "Please select user.");
    }
  };

  const [isOpen, setIsopen] = useState(false);

  const ToggleSidebar = () => {
    isOpen === true ? setIsopen(false) : setIsopen(true);
  };

  const scrollToBottom = () => {
    if (messagesRef.current) {
      messagesRef.current?.scrollIntoView({
        block: "end",
        inline: "center",
        behavior: "smooth",
        alignToTop: false,
      });
    }
  };

  useEffect(() => {
    messagesRef.current?.scrollIntoView({
      block: "end",
      inline: "center",
      behavior: "smooth",
      alignToTop: false,
    });
  }, [message]);

  return (
    <>
      {/*****************************Desktop View***************************/}
      <div className="d-none d-md-block">
        {role == LENDER ? (
          <LenderHeader />
        ) : role == RENTER ? (
          <RenterHeader />
        ) : (
          <Header />
        )}
        <div className="mainbox_outer mb-3 mb-md-0">
          <div className="top-head bottom-border pb-3 smalltopbar">
            <Container>
              <div className="row align-items-center">
                <Col lg={10} md={9} sm={12} className="px-lg-0">
                  <ul className="d-flex flex-wrap align-items-center subtop-menu messsagemenu">
                    <li className="active">
                      <Link
                        to={
                          userRole === RENTER
                            ? "/renter/message"
                            : "/lender/message"
                        }
                      >
                        Message Center
                      </Link>
                    </li>
                  </ul>
                </Col>
              </div>
            </Container>
          </div>
          <div className={"accountmain smallbg-white mb-0"}>
            <Container fluid>
              <Row className="d-flex justify-content-between flex-nowrap">
                <Col
                  xl={3}
                  lg={4}
                  md={4}
                  sm={12}
                  xs={12}
                  className="green-border overflow-auto bggrey overflow-auto leftslidetoggle"
                >
                  <MessageLeftBar
                    setOrderId={setOrderId}
                    setReceiverId={setReceiverId}
                    setProductId={setProductId}
                    scrollToBottom={scrollToBottom}
                  />
                </Col>
                <Col
                  xl={6}
                  lg={4}
                  md={4}
                  sm={12}
                  xs={12}
                  className={`green-border rightsidebar`}
                >
                  <div className="chat-view">
                    <div className="topbar-chatmain d-flex justify-content-between">
                      <div className="leftside-chatmain d-flex">
                        <span
                          onClick={() => {
                            setIsopen(false);
                          }}
                          className="righttoggleicon"
                        >
                          <ChatBackIcon />
                        </span>
                        <p className="font16 font700 mb-0">{chatUserName}</p>
                      </div>
                      <span onClick={ToggleSidebar}>
                        <ChatDetailsShowIcon />
                      </span>
                    </div>

                    {message && message.length > 0 && (
                      <>
                        <p className="text-center font16 font700 colordark mt-lg-5 mt-3">
                          {/* {moment().format("ll")} */}
                          Today
                        </p>
                        {page < totalPage && (
                          <span
                            className="load_previous"
                            onClick={handlePageChange}
                          >
                            Load Previous
                          </span>
                        )}
                      </>
                    )}

                    <ul
                      className={
                        message?.length
                          ? "chatboxmain chat-active"
                          : "chatboxmain empty-box"
                      }
                    >
                      {message && message?.length > 0 ? (
                        <div className="chatbox-inner">
                          {message?.map((data, index) => {
                            return (
                              <li
                                className="d-flex align-items-start"
                                key={index}
                              >
                                <img
                                  width="50"
                                  height="50"
                                  className="rounded-circle user-small"
                                  src={
                                    data?.sender?.profile_pic
                                      ? imageBase + data?.sender?.profile_pic
                                      : require("../../assets/images/DummyImg.png")
                                  }
                                  alt=""
                                />
                                <div className="ms-3">
                                  <h5 className="font24 font700 colordark mb-3">
                                    {data?.sender?.full_name}
                                    <span className="font16 font400 ms-2">
                                      {moment(
                                        convertUTCToLocal(data?.created_on)
                                      ).format(" h:mm a")}
                                    </span>
                                  </h5>
                                  {data?.message_type == IMAGE ? (
                                    <div className="chat-img ">
                                      <img
                                        src={
                                          data?.message_file &&
                                          imageBase + data?.message_file
                                        }
                                        alt="img"
                                      />
                                      <p className="msg font16 font400 colordark mb-0">
                                        {data?.message}
                                      </p>
                                    </div>
                                  ) : (
                                    <p className="msg font16 font400 colordark mb-0">
                                      {data?.message}
                                    </p>
                                  )}
                                </div>
                              </li>
                            );
                          })}
                        </div>
                      ) : (
                        <div
                          className={
                            "justify-content-center d-flex align-items-center h60vh chatbox-empty"
                          }
                        >
                          <p className="font16 mb-0 ">No messages to display</p>
                        </div>
                      )}
                      <div ref={messagesRef} />
                    </ul>

                    {receiverId && orderId && (
                      <div className="d-md-flex align-items-center gap-3 mt-3 position-relative">
                        <label htmlFor="chooseFile">
                          <ImageIcon />
                        </label>

                        <Form.Control
                          type="text"
                          name="message"
                          value={values.message}
                          onChange={handleChange}
                          placeholder="Type a message..."
                          className="send-msg"
                          onKeyDown={handleKeydown}
                        />
                        <input
                          type="file"
                          accept="image/*"
                          name="fileUrl"
                          hidden
                          id="chooseFile"
                          onClick={(e) => {
                            e.target.value = null;
                          }}
                          onChange={handleFile}
                        />
                        {
                          <span
                            className="snd-btn rounded-circle colorgreen"
                            onClick={() => {
                              if (isValid && values?.message?.trim()) {
                                handleSubmit();
                              }
                            }}
                          >
                            <SendMsgIcon />
                          </span>
                        }
                      </div>
                    )}
                  </div>
                </Col>
                <Col xl={3} lg={4} md={4} sm={12}>
                  {userRole === LENDER ? (
                    <MessageLenderSummary
                      productId={productId}
                      orderId={orderId}
                      receiverId={receiverId}
                    />
                  ) : (
                    <MessageRentalSummary
                      productId={productId}
                      orderId={orderId}
                      receiverId={receiverId}
                    />
                  )}
                </Col>
              </Row>
            </Container>
          </div>
        </div>
        <Footer />
        {loading && <Loader />}
      </div>

      {/*****************************Mobile View***************************/}
      <div className="d-block d-md-none mobile_chat">
        <NewChat />
      </div>

      {/****************************Modal to send Image********************/}

      <Modal
        show={show}
        onHide={handleClose}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="delete-items"
      >
        <div className="middle-area text-start">
          <div className="d-flex justify-content-between align-items-center mb-2">
            <h4 className="text-start">Media</h4>
            <span onClick={handleClose}>
              <CrossIcon />
            </span>
          </div>
          <Modal.Body className="px-0 pb-0">
            <Row>
              <Col sm={12} className="mx-auto">
                <div className="product-img">
                  <input
                    type="file"
                    accept="image/*"
                    name="fileUrl"
                    hidden
                    id="chooseFile"
                    onClick={(e) => {
                      e.target.value = null;
                    }}
                    onChange={handleFile}
                  />

                  <label htmlFor="chooseFile">
                    <img
                      src={
                        values?.message_file &&
                        URL.createObjectURL(values?.message_file)
                      }
                      alt="img"
                      className="h-auto"
                    />
                  </label>
                </div>
                <div>
                  <p className="font12 font700 colordark mb-1">Caption</p>
                  <Form.Control
                    as="textarea"
                    rows={2}
                    className="h-auto px-2 "
                    name="caption"
                    value={values?.caption}
                    onChange={handleChange}
                  />
                </div>

                <div className="text-start">
                  <Button
                    className="btn btn-theme mt-3 mb-1"
                    type="button"
                    disabled={isSubmitting}
                    onClick={handleSubmit}
                  >
                    Send
                  </Button>
                </div>
              </Col>
            </Row>
          </Modal.Body>
        </div>
      </Modal>
    </>
  );
};
export default Chat;
